import React, { Component } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerImage from '../media/newlogolabel.png';
import YetiLogo from '../media/yetialone.png';

var L = window.L;
const icon = L.icon({iconUrl: MarkerImage, iconSize: [75,75]});
const icon2 = L.icon({iconUrl: YetiLogo, iconSize: [40,40]})

export default class FindUs extends Component {
    render() {
        return (
            <div>
                <h1>Find FrozoBites</h1>
                <MapContainer center={[32.9342, -97.2293]} zoom={10} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[32.943410, -97.255940]} icon={icon}>
                        <Popup>
                            <b>Office Location</b><br />5821 Edd Farm Rd #2930, Fort Worth, TX 76244
                                <br />Monday 9AM-9 PM
                                <br />Tuesday 9AM-9 PM
                                <br />Wednesday 9AM-9 PM
                                <br />Thursday 9AM-4 PM
                                <br />Friday 9AM-3 PM
                                <br />Saturday 3-9 PM
                                <br />Sunday 3-9 PM
                        </Popup>
                    </Marker>
                    {this.props.locationData.map(location => {
                        if(location.Status == "Enabled")
                        {
                            return (
                                <Marker position={[location.Address.latitude, location.Address.longitude]} icon={icon2}>
                                    <Popup>
                                        <b>{location.Market_Name}</b><br />{location.Real_Address}<br />{location.Date_field}<br />{location.Hours_Available}
                                    </Popup>
                                </Marker>
                            )
                        }
                    })}
                </MapContainer>
                <h4>Map Key</h4>
                <p>Office Location:</p><img src={MarkerImage} className="map-image"/>
                <p>Market Locations:</p><img src={YetiLogo} className="map-image"/>
            </div>
            
            
            
            // <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
            //     <TileLayer
            //         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            //         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            //     />
            //     {/* <Marker position={[51.505, -0.09]}>
            //         <Popup>
            //         A pretty CSS3 popup. <br /> Easily customizable.
            //         </Popup>
            //     </Marker> */}
            // </MapContainer>
        )
    }
}