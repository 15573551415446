import React, { Component } from 'react'
import Nav from './Nav'
import Items from './Items'

export default class Shop extends Component {  
    render() {
        return (
            <div className="container-xxl" id="shop-area">
                <h1>Check Out Our Treats:</h1>
                <div className="card-group" id="shopContainer">
                    {this.props.items.map(item => {
                        return <Items key={item.id} item={item} addToCart={this.props.addToCart} />
                    })}
                </div>
            </div>
        )
    }
}