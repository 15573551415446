import React, { Component } from 'react'

export default class Items extends Component {
    constructor(props) {
        super(props)

        this.state = {
            itemId: "",
            sizeSelection: "",
            itemQuantity: 1,
            itemCost: 0.00
        }
    }

    render() {
        var modalID;
        return (
            <div className="col-4" id="itemCard">
                <img className="card-img-top" src={this.props.item.itemImages[0]} alt="Product Image" id="itemImage" />
                <div className="card-body">
                    <h5 className="card-title">{this.props.item.itemName}</h5>
                    <button type="button" className="btn btn-primary" id="view-button" data-toggle="modal" data-target={"#itemModal" + this.props.item.itemName.replace(" ", "_")}>View</button>
                </div>
                <div className="modal fade modal-card" id={"itemModal" + this.props.item.itemName.replace(" ", "_")} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" id="modal-card">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{this.props.item.itemName}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <img className="card-img-top" src={this.props.item.itemImages[0]} alt="Product Image" id="itemCardImage" />
                                <form className="size-selection">
                                    <button className="btn btn-secondary dropdown-toggle" type="button" id={"dropdownMenuButton " + this.props.item.itemName.replace(" ", "_")} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Select Size
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {this.props.item.variations.map(variation => {
                                            return <button className="dropdown-item" type="button" onClick={() => {document.getElementById("dropdownMenuButton " + this.props.item.itemName.replace(" ", "_")).innerText = `$${variation.price.amount / 100} - ${variation.name}`; this.setState({itemId: variation.itemId, sizeSelection: variation.name, itemCost: (variation.price.amount / 100).toFixed(2)})}}>${variation.price.amount / 100} - {variation.name}</button>
                                        })}
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.props.addToCart({itemName: this.props.item.itemName, itemId: this.state.itemId, itemSize: this.state.sizeSelection, itemQuantity: this.state.itemQuantity, itemImage: this.props.item.itemImages[0], itemPrice: this.state.itemCost})}>Add to Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}