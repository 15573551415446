import "../App.css";
import React, { Component } from "react";
import HeaderLogo from "../media/newlogolabel.png";

export default class Nav extends Component {
  constructor() {
    super()

    this.state = {
      delivery: ""
    }
  }
  render() {
    return (
      <div className="Header-container">
        <div className="container">
          <img src={HeaderLogo} className="logo" alt="Logo Thumbnail"></img>
          <div className="container"></div>
          <header
            className="d-flex justify-content-center py-3"
            id="nav-header"
          >
            <ul className="nav nav-pills Nav-ul">
              <li className="nav-item">
                <a href="/" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="/shop" className="nav-link">
                  Shop
                </a>
              </li>
              <li className="nav-item">
                <a href="/find-us" className="nav-link">
                  Find Us
                </a>
              </li>
              <li className="nav-item">
                <a href="https://www.instagram.com/invites/contact/?igsh=1b18wnablpoh5&utm_content=rdaixdv" className="nav-link">
                  Instagram
                </a>
              </li>
              <li className="nav-item">
                <a href="https://www.facebook.com/profile.php?id=61559807376273&mibextid=ZbWKwL" className="nav-link">
                  Facebook
                </a>
              </li>
              <li className="nav-item">
                <a href="https://www.tiktok.com/@frozobitesshop?_t=8mXMIQ1yGQl&_r=1" className="nav-link">
                  TikTok
                </a>
              </li>
            </ul>
            <button
              className="btn my-2 my-sm-0 checkout"
              id="checkout"
              type="submit"
              data-toggle="modal"
              data-target="#cartModal"
            >
              Checkout
              <span className="badge badge-light" id="checkout-badge">
                {this.props.cart.length}
              </span>
            </button>
          </header>
        </div>
        <div
          className="modal fade modal-card"
          id="cartModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" id="modal-card">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Your Cart
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.props.cart.map(item => {
                  return (
                    <div className="container">
                      <div className="row align-items-start">
                        <div className="col">
                        <img src={item.itemImage[0]} className="cart-thumbnail"></img>
                        </div>
                        <div className="col" id="cart-col">
                          <h5>{item.itemName}</h5>
                          <p>${item.itemPrice}</p>
                          <p>{item.itemSize}</p>
                        </div>
                        <div className="col" id="cart-col-button">
                        <button type="button" className="btn btn-danger" onClick={() => this.props.removeFromCart(item.itemId)}>Remove</button>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <form className="size-selection">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id={"dropdownMenuButton"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Select Delivery Method
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <button className="dropdown-item" type="button" onClick={() => {document.getElementById("dropdownMenuButton").innerText = "Delivery"; this.setState({delivery: true})}}>Delivery</button>
                    <button className="dropdown-item" type="button" onClick={() => {document.getElementById("dropdownMenuButton").innerText = "Pickup"; this.setState({delivery: false})}}>Pickup</button>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {this.state != "" ? this.props.createCheckout(this.state.delivery) : console.log("")}}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
