import './App.css';
import Snowfall from 'react-snowfall'
import React, { Component } from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Nav from './components/Nav';
import Home from './components/Home';
import Shop from './components/Shop';
import FindUs from './components/FindUs';
import ThankYou from './components/ThankYou';

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      items: [],
      locations: [],
      cart: []
    }
  }

  getCatalog() {
    fetch("https://fbmw-19a671772965.herokuapp.com/catalog", {
      method: "POST"
    }).then(resp => resp.json()).then(data => this.setState({items: data}));
  }

  getLocations() {
    fetch("https://fbmw-19a671772965.herokuapp.com/locations", {
      method: "POST"
    }).then(resp => resp.json()).then(data => this.setState({locations: data}));
  }

  createCheckout = (delivery) => {
    let checkoutArray = [];

    this.state.cart.map(item => {
      checkoutArray.push({"quantity": item.itemQuantity.toString(), "catalog_object_id": item.itemId})
    });

    let checkoutParams = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accepts': 'application/json'
      },
      body: JSON.stringify({"items": checkoutArray, method: delivery})
    }

    fetch("https://fbmw-19a671772965.herokuapp.com/checkout", checkoutParams)
    .then(resp => resp.json()).then(data => {console.log(data); window.location.assign(data.payment_link.url)});
  }

  addToCart = (cartItem) => {
    this.setState({ cart: [...this.state.cart, cartItem] });
  }

  removeFromCart = (itemId) => {
    let filteredArray = this.state.cart.filter(item => item.itemId !== itemId)
    this.setState({cart: filteredArray});
  }

  componentDidMount() {
    this.getLocations();
    this.getCatalog(); 
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Snowfall 
            style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
            }}
          />
          <Nav props={this.state} cart={this.state.cart} createCheckout={this.createCheckout} removeFromCart={this.removeFromCart} />
          <Switch>
            <Route exact path={"/"} render={props => (
              <Home />
            )} />
            <Route exact path={"/shop"} render={props => (
              <Shop props={this.state} items={this.state.items} addToCart={this.addToCart} />
            )} />
            <Route exact path={"/find-us"} render={() => (
              <FindUs props={this.state} locationData={this.state.locations} />
            )} />
            <Route exact path={"/thankyou"} render={props => (
              <ThankYou />
            )}/>
          </Switch>
          <div className="footer">FrozoBites © 2024</div>
        </BrowserRouter>
      </div>
    );
  }
}
