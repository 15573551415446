import React, { Component } from 'react'
import BannerImage from '../media/newbackcard.png'
import Items from './Items';

export default class Home extends Component {
    constructor() {
      super();
    
      this.state = {
        items: []
      }
    }
    
    getCatalog() {
      fetch("https://fbmw-19a671772965.herokuapp.com/bestsellers", {
        method: "POST"
      }).then(resp => resp.json()).then(data => this.setState({items: data}));
    }
    
    componentDidMount() {
        this.getCatalog();
    }

    render() {
        return (
            <div>
                {/* Home Area */}
                <div className="container-xxl" id="home-area">
                    <img src={BannerImage} className="img-fluid" id="banner-image"></img>
                    <div className="text-center bg-text inner">
                        <h1 className="banner-text">Satisfy Your Sweet Tooth With Frozen Delights</h1>
                    </div>  
                </div>
                {/* About Area */}
                <div className="container-xxl" id="about-area">
                    <h1>About FrozoBites</h1>
                    <p className="about-text">Premium freeze-dried candy company. Transforming beloved confections into delightful bite-sized snacks with intense flavors. Our meticulous selection of fine ingredients ensures every bite bursts with vibrant flavors. Light, crispy, and shelf- stable treats. Perfect for on-the-go, gifting, and events. Exceptional customer service and sustainable practices. Join us on a flavor-filled journey redefining the candy experience. Indulge in the crisp, delectable world of FrozoBites and all of our creations.</p>
                    <p className="about-text">
                        Craving a Candy Adventure? FrozoBites transforms your favorite treats into light, crispy, and explodingly flavorful bite-sized snacks.
                        <br />
                        Imagine: The intense taste of your favorite candy, now in a convenient, shelf-stable form. Perfect for gifting, events, or on-the-go adventures.
                        <br />
                        FrozoBites: Where premium ingredients meet sustainable practices to create an unforgettable candy experience.
                    </p>
                </div>
                {/* Best Products Area */}
                <div className="container-xxl" id="shop-area">
                    <h1>Best Sellers:</h1>
                    <div className="card-group" id="itemContainer" style={{justifyContent: "center"}}>
                        {this.state.items.map(item => {
                        return <Items key={item.id} item={item} />
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
