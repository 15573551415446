import '../App.css';
import Snowfall from 'react-snowfall'
import React, { Component } from 'react';

export default class ThankYou extends Component {
    render() {
        return (
            <div className="thankyou">
                <Snowfall 
                    style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    }}
                    snowflakeCount={5000}
                />
                <div className="thankyou-text-container">
                    <h1 className="thankyou-text">Thank You For Your Purchase!</h1>
                    <h3 className="thankyou-details">Please Check Your Email For Details</h3>
                </div>
            </div>
        )
    }
}